@left_sidebar_width: 260px;
@gap_size: 28px;

@top-bar-height: 60px;

.settings-container {
  width: 100%;
  height: 100%;

  &__top-bar {
    width: 100%;
    height: @top-bar-height;
    line-height: @top-bar-height;
    padding: 0 16px;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #E0E0E0;
  }

  &__body {
    width: 100%;
    height: calc(100% - @top-bar-height);
  }
}

.profile-info {
  display: flex;
  width: 100%;
  padding: 48px 60px;
  box-sizing: border-box;
  justify-content: space-between;

  &__left-bar {
    width: @left_sidebar_width;

    &--avatar-block {
      display: flex;
      width: @left_sidebar_width;
      height: @left_sidebar_width;
      padding: 54px 0;
      border: 1px solid #EAEBEE;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    &--name {
      color: #9E9E9E;
      margin-top: 10px;
    }

    &--nav {
      width: 100%;
      margin-top: 16px;
      border: 1px solid #EAEBEE;
    }

    &--nav-item {
      width: 100%;
      height: 54px;
      line-height: 54px;
      border-bottom: 1px solid #E2E2E2;
      padding: 0 28px;
      box-sizing: border-box;
      cursor: pointer;

      i {
        margin-left: 10px;
      }

      i.warning {
        color: red;
      }

      &:hover {
        color: #033AEE;
      }

      &:last-child {
        border-bottom: 0;
      }

      &.current {
        position: relative;
        color: #033AEE;

        &:before {
          content: '';
          position: absolute;
          left: -1px;
          top: 50%;
          display: block;
          width: 2px;
          height: 32px;
          background-color: #033AEE;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__right-bar {
    width: calc(100% - @left_sidebar_width - @gap_size);
    margin-left: @gap_size;
  }
}
