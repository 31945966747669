.create-shipment-modal {
  .ant-modal-body {
    padding: 0;
  }

  &__body {
    width: 100%;
    padding: 24px;

    input {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;

      &::placeholder {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.2);
      }
    }

    textarea {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      resize: none;

      &::placeholder {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.2);
      }
    }

    .ant-form-item {

      .ant-form-item-label {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    height: 80px;
    padding: 0 30px;
    box-sizing: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    justify-content: flex-end;
    align-items: center;

    &--btn {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #fff;
      padding: 8px 18px;
      border-radius: 4px;
      margin-left: 14px;
      border: 0;
      cursor: pointer;

      i {
        margin-right: 10px;
      }

      &.cancel {
        color: #6D7584;
        background-color: #fff;
        border: 1px solid #6D7584;
      }

      &.create {
        background: #0170FF;
      }
    }
  }
}
