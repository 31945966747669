.account-info {
  width: 100%;

  &__account-block {
    border: 1px solid #EDEBEB;
    border-radius: 6px;

    &--label {
      display: flex;
      width: 100%;
      height: 48px;
      padding: 0 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #EDEBEB;
      align-items: center;
      justify-content: space-between;
    }

    &--label-btn {
      color: #2C6CE4;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }

    &--content {
      width: 100%;
      padding: 36px 20px 46px 20px;
      box-sizing: border-box;
    }

    &--info-row {
      display: flex;
      width: 100%;
      gap: 36px;
      margin-top: 38px;

      &:first-child {
        margin-top: 0;
      }
    }

    &--info-block {
      width: 50%;
      color: #4E5969;

      label {
        display: block;
        margin-bottom: 4px;
      }

      span {
        display: block;
        width: 100%;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        padding: 0 8px;
        box-sizing: border-box;
        background: #F7F8FA;

        &.disabled {
          background: #E0E0E0;
        }
      }
    }
  }
}
