.containers-table {
  table {
    border: 1px solid #ECECEC;
    border-bottom: 0;
  }

  .ant-table-thead {
    .ant-table-cell {
      height: 60px;
      padding: 0 16px;
      color: #797878;
      font-weight: 500;
      font-size: 14px;
      background-color: #FFF;
      border-right: 1px solid #ECECEC;
      text-align: center;
      border-bottom-width: 3px;

      &.ant-table-row-expand-icon-cell {
        display: none;
      }

      &:nth-child(1) {
        border-right: 0;
      }

      &:nth-child(2) {
        text-align: left;
      }

      &:last-child {
        border-right: 0;
      }

      &:before {
        display: none;
      }
    }
  }

  .ant-table-tbody {
    tr {
      background-color: #fff;

      td {
        color: #797878;
        border: 0;
        border-right: 1px solid #ECECEC;
        border-bottom: 1px solid #ECECEC;

        &.ant-table-row-expand-icon-cell {
          display: none;
        }

        &:nth-child(1) {
          border-right: 0;
          padding: 0 16px;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }

    tr.ant-table-expanded-row {
      td {
        padding: 28px 48px;
      }
    }
  }

  &__unitinfo {
    cursor: pointer;

    p {
      margin-bottom: 0;

      &.bol {
        color: #969696;
        font-size: 12px;
        font-weight: 400;
      }

      &.cntr {
        color: #707070;
        font-size: 14px;
        font-weight: 400;
      }

      b {
        display: inline-block;
        font-weight: 400;
        background: #F4F4F4;
        padding: 2px 6px;
        min-width: 110px;
        min-height: 20px;
      }
    }
  }

  &__status {
    p {
      margin: 0;
      text-align: center;
      font-weight: 400;
      font-size: 12px;

      &.label {
        line-height: 40px;
      }

      &.info {
        line-height: 20px;
      }

      label.available {
        color: #EF9124;
      }

      label.unavailable {
        color: #FF0000;
      }

      span {
        color: #FFF;
        border-radius: 6px;
        padding: 6px 12px;

        &.OnVessel {
          color: #FAAD14;
          background: #FFFBE6;
          border: 1px solid #FFCCC7;
        }

        &.Arrived {
          color: #1677FF;
          background: #E6F4FF;
          border: 1px solid #91CAFF;
        }

        &.Discharged {
          color: #1677FF;
          background: #E6F4FF;
          border: 1px solid #91CAFF;
        }

        &.NotFound {
          color: #FF4D4F;
          background: #FFF2F0;
          border: 1px solid #FFCCC7;
        }

        &.GateOut {
          color: #52C41A;
          background: #F6FFED;
          border: 1px solid #B7EB8F;
        }

        &.EmptyReturned {
          color: #000;
          background: #F5F5F5;
          border: 1px solid #D9D9D9;
        }

        &.Loading {
          color: #1677FF;
          font-size: 14px;

          &:after {
            content: '...';
            display: inline;
          }
        }

        &.Other {
          color: #000;
          background: #F5F5F5;
          border: 1px solid #D9D9D9;
        }
      }

      &.desc {
        color: #EB5851;
        line-height: 18px;
        margin-top: 8px;
      }
    }
  }

  &__pickup-available {
    color: #FFF;
    font-size: 14px;

    span.Yes {
      padding: 4px 12px;
      background-color: #58F067;
      border-radius: 4px;

      &:before {
        font-family: "Font Awesome 6 Free";
        content: '\f058';
        font-size: 12px;
        margin-right: 4px;
      }
    }

    span.No {
      padding: 4px 12px;
      background-color: #EC8205;
      border-radius: 4px;

      &:before {
        font-family: "Font Awesome 6 Free";
        content: '\f057';
        font-size: 12px;
        margin-right: 4px;
      }
    }
  }

  &__holds {
    display: inline-block;
    overflow-x: auto;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    vertical-align: text-top;

//     &::-webkit-scrollbar {
//       height: 20px;
//     }

    &--block {
      display: inline-block;
      width: 80px;
      color: #5A5858;
      flex-shrink: 0;

      p {
        text-align: center;
        margin: 0;
        font-size: 14px;
        line-height: 22px;

        &.status {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin-top: 10px;
        }
      }
    }
  }

  &__last-free-day {
    text-align: center;
    line-height: 1.6;
  }

  &__pickup-schedule {
    text-align: center;
  }

  &__actions {
    text-align: center;
    font-size: 16px;
    color: #6236FF;

    i {
      cursor: pointer;
    }
  }
}

.containers-table-section {
  position: relative;

  &__selector {
    position: sticky;
    bottom: 10%;
    left: 50%;
    display: inline-block;
    height: 50px;
    background-color: #FFF;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.05);
    transform: translateX(-50%);

    &--item {
      display: inline-block;
      height: 100%;
      line-height: 50px;
      font-weight: 400;
      font-size: 16px;
      padding: 0 20px;
      cursor: pointer;

      b {
        font-size: 18px;
        font-weight: 400;
        vertical-align: top;
      }

      i {
        font-size: 24px;
        vertical-align: middle;
        margin-right: 8px;
      }

      &.highlight {
        color: #fff;
        background-color: #0051DC;
      }
    }
  }
}

.container-timeline {
  &__item {
    color: #999;

    &.activated {
      color: #000;
    }
  }

  // Antd rewrite
  .ant-timeline-item {
    height: 64px;
  }

  .ant-timeline-item-label {
    width: 80px !important;
  }

  .ant-timeline-item-tail {
    left: 92px !important;
  }

  .ant-timeline-item-head {
    left: 92px !important;
  }

  .ant-timeline-item-content {
    width: calc(100% - 120px) !important;
    margin: 0 0 0 14px !important;
    left: 92px !important;
  }
  // Antd rewrite end

  &__date {
    &--info {
      text-align: right;
    }
  }

  &__content {
    &--title {
      font-size: 16px;
    }

    &--info {
      font-size: 12px;
    }
  }
}

.container-detail-drawer {
  .ant-drawer-body {
    padding: 20px;
  }
}

.container-detail {
  width: 100%;
  height: 100%;

  &__vessel-info {
    padding: 16px 20px;
    background-color: #F5F5F5;
    margin-bottom: 10px;

    &--block {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.4;
      color: #777777;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }

      &.inline {
        display: flex;
        justify-content: space-between;
      }

      label {
        display: inline-block;
        width: 80px;
        color: #999999;
      }

      span {
        display: inline-block;
        width: 48%;
      }
    }
  }

  &__timeline {
    padding: 20px 0;

    &--block {
      position: relative;
      padding-bottom: 15px;

      &:after {
        content: '';
        position: absolute;
        left: 8px;
        top: 22px;
        display: block;
        border-right: 2px solid #0170FF;
        height: calc(100% - 18px);
      }

      &:last-child {
        padding-bottom: 0;

        &:after {
          display: none;
        }
      }
    }

    &--block-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      margin-bottom: 10px;

      &:before {
        content: '\f00c';
        font-family: 'fontawesome';
        display: inline-block;
        width: 22px;
        height: 22px;
        line-height: 22px;
        border-radius: 100%;
        background-color: #0170FF;
        vertical-align: bottom;
        margin-right: 12px;
        color: #fff;
        text-align: center;
      }
    }

    &--event-block {
      display: flex;
      padding: 0 0 0 34px;
      margin-bottom: 8px;
      color: #888080;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.6;
      justify-content: space-between;

      span {
        display: inline-block;

        &:first-child {
          width: 60%;
        }
      }
    }
  }

  &__terminal {
    padding: 16px 20px;
    background-color: #F5F5F5;

    &--block {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.4;
      color: #999;
      margin-bottom: 10px;

      label {
        margin-right: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
