.appointments-table {
  table {
    border: 1px solid #ECECEC;
    border-bottom: 0;
  }

  .ant-table-thead {
    .ant-table-cell {
      height: 60px;
      padding: 0 16px;
      color: #797878;
      font-weight: 500;
      font-size: 14px;
      background-color: #FFF;
      border-right: 1px solid #ECECEC;
      text-align: center;
      border-bottom-width: 3px;

      &:last-child {
        border-right: 0;
      }

      &:before {
        display: none;
      }
    }
  }

  .ant-table-tbody {
    tr {
      background-color: #fff;

      td {
        color: #5A5858;
        font-size: 14px;
        border: 0;
        border-right: 1px solid #ECECEC;
        border-bottom: 1px solid #ECECEC;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  &__status {
    p {
      margin: 0;
      text-align: center;
      font-weight: 400;
      font-size: 12px;

      span {
        color: #FFF;
        background: #58F067;
        border-radius: 6px;
        padding: 4px 8px;
      }

      &.desc {
        color: #EB5851;
        margin-top: 8px;
      }
    }
  }

  &__unitinfo {
    p.id {
      font-size: 14px;
      margin-bottom: 2px;
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__actions {
    color: #9A9A9A;

    i {
      padding: 0 8px;
      cursor: pointer;
    }
  }
}
