@self: channel-chat;
@chat-input-default-height: 220px;

.@{self} {
  position: relative;
  width: 100%;
  height: 100%;

  &__chat-content {
    display: flex;
    width: 100%;
    height: calc(100% - @chat-input-default-height);
    padding: 22px;
    border-bottom: 1px solid #E0E0E0;
    overflow-y: auto;
    flex-flow: column-reverse;

    &--no-message, &--loading {
      flex: auto;
      text-align: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &__chat-input {
    width: 100%;
    height: @chat-input-default-height;

    &--tool-bar {
      width: 100%;
      height: 60px;
      line-height: 60px;
      padding: 0 24px;
      color: #594E4E;
      box-sizing: border-box;

      i {
        font-size: 20px;
        padding: 10px;
        font-weight: 400;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background-color: #F9F9F9;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--input-area {
      position: relative;
      width: 100%;
      height: calc(100% - 60px);
      padding: 0 34px 8px 34px;
      box-sizing: border-box;
      overflow-y: auto;

      textarea {
        width: 100%;
        height: 100%;
        line-height: 20px;
        font-size: 14px;
        border: 0;
        outline: 0;
        resize: none;
        padding: 0;
        vertical-align: top;

        &::placeholder {
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    &--send-btn {
      position: fixed;
      bottom: 14px;
      right: 16px;
      background: #0170FF;
      border-radius: 4px;
      padding: 12px 28px;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }

  &__message {
    display: flex;
    width: 100%;
    gap: 8px;
    margin-bottom: 24px;
    flex-flow: row;

    &--avatar {
      width: 46px;
      height: 46px;
    }

    &--content {
      position: relative;
      max-width: calc(80% - 40px);
    }

    &--info {
      text-align: left;
      margin-bottom: 14px;
    }

    &--username {
      display: inline-flex;
      color: #393939;
      font-size: 16px;
      line-height: 20px;
      gap: 10px;

      span {
        font-size: 14px;
        color: #C9C9C9;
        vertical-align: bottom;
      }
    }

    &--role {
      line-height: 14px;
      color: #CCC2C2;
      font-size: 12px;
      margin-top: 4px;
    }

    &--body {
      font-size: 14px;
      color: #444343;
      padding: 12px;
      background-color: #F0F0F0;
      margin-top: -4px;
      border-radius: 0px 8px 8px 8px;
      color: #444343;
      white-space: pre-wrap;
      word-break: break-word;

      &.card {
        padding: 0;
      }

      p {
        &:last-child {
          margin: 0;
        }
      }
    }

    &--read-info {
      position: absolute;
      bottom: 0;
      left: -32px;;
      width: 24px;
      height: 24px;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
      color: #DBDBDB;
      border-radius: 100%;
      border-width: 1px;
      border-style: solid;
      border-color: #DBDBDB;

      &.unread {
        color: #2C6CE4;
        border-color: #2C6CE4;
      }
    }

    &--attachment {
      text-align: center;
      cursor: pointer;
    }

    &--file-category {
      display: block;
      height: 48px;
      line-height: 48px;
      color: #FFF;
      font-weight: 400;
      padding: 0 24px;
      font-size: 16px;
      background: #FDD547;
      border-radius: 0px 8px 0px 0px;

      i {
        margin-right: 6px;
      }
    }

    &--filename {
      display: block;
      height: 48px;
      line-height: 48px;
      color: #2C6CE4;
      padding: 0 24px;
      font-weight: 400;
      font-size: 14px;
      background: #F0F0F0;
      border-radius: 0px 0px 8px 8px;
    }

    &.sent {
      flex-flow: row-reverse;

      .@{self}__message--info {
        text-align: right;
      }

      .@{self}__message--username {
        flex-direction: row-reverse;
      }

      .@{self}__message--body {
        border-radius: 8px 0px 8px 8px;
      }

      .@{self}__message--file-category {
        border-radius: 8px 0px 0px 0px;
      }
    }
  }

  &__uploader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);

    &--drag-area {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 460px;
      height: 180px;
      transform: translate(-50%, -50%);

      .ant-upload {
        border-color: #999 !important;
        border-width: 2px !important;
      }
    }
  }
}

.at-user-menu {
  display: flex;
  padding: 4px;

  &__avatar {
    margin-right: 8px;
  }

  &__info {
    font-weight: 400;

    &--name {
      line-height: 20px;
      font-size: 14px;
    }

    &--email {
      line-height: 16px;
      font-size: 12px;
      color: #ABA9A9;
    }
  }
}
