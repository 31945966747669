.vessel-schedule {
  width: 100%;
  height: 100%;
  padding: 60px;

  &__search-bar {
    margin-bottom: 20px;

    &--input {
      display: inline-block !important;
      margin-right: 12px !important;

      &.middle {
        width: 320px !important;
      }

      &.small {
        width: 200px !important;
      }
    }
  }
}
