.company-form {
  width: 100%;

  &__block {
    width: 100%;
    color: #646566;
    margin-bottom: 14px;

    label {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px;
    }

    input, select, textarea {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      border: 1px solid #CCC6C6;
      box-sizing: border-box;

      &.error {
        border: 1px solid #FF0000;
      }
    }

    textarea {
      height: 106px;
      resize: none;
    }

    p {
      font-size: 14px;
      color: #FF0000;
    }

    &.inline {
      display: flex;
      row-gap: 48px;
      justify-content: space-between;
    }

    &--column {
      width: calc(50% - 24px);
    }
  }

  &__btns-group {
    button.register {
      width: 248px;
      height: 48px;
      color: #fff;
      font-weight: 500;
      background: #0170FF;
      border-radius: 4px;
      border: 0;
      cursor: pointer;
    }

    button.cancel {
      width: 160px;
      height: 48px;
      font-weight: 500;
      border-radius: 4px;
      border: 0;
      margin-left: 24px;
      cursor: pointer;
    }
  }
}
