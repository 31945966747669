.join-company-modal {
  width: 100%;

  &__block {
    width: 100%;
    color: #747474;
    margin-bottom: 32px;

    &--label {
      margin-bottom: 8px;
    }

    input {
      width: 100%;
      height: 36px;
      line-height: 36px;
      padding: 0 10px;
      box-sizing: border-box;
      border: 1px solid #D8D8D8;
    }

    &--content {
      width: 100%;
      height: 36px;
      line-height: 36px;
      padding: 0 10px;
      box-sizing: border-box;
      background: #E2E2E2;
    }
  }
}
