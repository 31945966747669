.shipment-chat-overview {
  width: 100%;
  padding: 20px 24px 0 24px;
  background: #F2F2F2;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 40px;

  &__header {
    display: flex;

    &--name {
      width: calc(100% - 50px);
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 18px;

      p {
        line-height: 20px;
        margin-bottom: 14px;

        &:last-child {
          margin: 0;
        }

        label {
          margin-right: 14px;
        }

        span {
          color: #6236FF;
        }
      }

      p.date {
        color: #7C7C7C;
        font-size: 14px;
        font-weight: 400;

        i {
          margin-right: 8px;
        }
      }
    }

    &--logo-info {
      position: relative;
    }

    &--logo {
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 100%;
      font-size: 18px;
      text-align: center;
      color: #FFF;
      background: #FDD547;
    }

    &--group-info {
      position: absolute;
      top: 48px;
      width: 48px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      background-color: #000;
      opacity: 0.3;
      border-radius: 30px;
      color: #FFF;
      padding: 2px 0;
      transform: translateY(-50%);

      i {
        font-size: 10px;
        margin-right: 4px;
      }
    }
  }

  &__desc {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #444343;
    padding-bottom: 18px;
    border-bottom: 1px solid #D3D3D3;
  }

  &__footer {
    display: flex;
    width: 100%;
    height: 64px;
    font-weight: 400;
    font-size: 16px;
    color: #A0A0A0;
    align-items: center;
    justify-content: space-between;

    &--creator {
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }

    &--date {
    }
  }
}
