.shipment-overview-block {
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  box-sizing: border-box;

  &__name-block {
    display: flex;
    width: 100%;
    height: 22px;
    line-height: 22px;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 18px;
    justify-content: space-between;

    span {
      display: inline-block;
      width: calc(100% - 22px);
    }

    i {
      display: inline-block;
      width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      color: #FDD547;
    }
  }

  &__desc {
    height: 60px;
    font-size: 14px;
    line-height: 20px;
    color: #444343;
    margin-bottom: 6px;
  }

  &__process-bar {
    width: 100%;
    height: 18px;
    line-height: 18px;
    margin-bottom: 8px;

    span.bar {
      display: inline-block;
      width: calc(100% - 48px);
      height: 8px;
      background-color: #F7F3F3;
    }

    span.text {
      display: inline-block;
      width: 48px;
      text-align: right;
      font-weight: 400;
      font-size: 12px;
      padding-right: 12px;
      box-sizing: border-box;
    }
  }

  &__avatars {
    display: flex;
    width: 100%;
    height: 36px;
  }
}
