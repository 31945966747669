.invite-members {
  &__search {
    display: flex;
    width: 100%;
    align-items: center;

    &--select-members {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 14px;

      .ant-select-selection-item-content {
        display: inline-flex !important;
        align-items: center !important;
      }

      .ant-select-selector {
        width: 100%;
        border: 1px solid #888888 !important;
        border-radius: 4px !important;
      }

      .ant-select-open {
        &.ant-select-focused {
          .ant-select-selector {
            border-color: #2C6CE4 !important;
            box-shadow: 0 0 0 1px #2C6CE4 !important;
          }
        }
      }

      .ant-select-item-option-active {
        background: #FFF !important;
      }

      .ant-select-lg {
        font-size: 14px;
      }
    }
  }

  &__existing {
    margin-top: 20px;

    &--label {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }

    &--member-block {
      display: flex;
      width: calc(100% + 36px);
      height: 50px;
      margin-top: 6px;
      margin: 6px -18px 0 -18px;
      padding: 0 18px;
      align-items: center;
      border-radius: 50px;

      &:hover {
        background: #F1F3F4;
      }
    }
  }
}
