@header-height: 80px;

.shipment-files {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  &__header {
    display: flex;
    width: 100%;
    height: 80px;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EAEAEA;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    &--upload-btn {
      width: 148px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      font-size: 14px;
      color: #FFF;
      background-color: #0170FF;
      cursor: pointer;

      i {
        font-size: 18px;
        margin-right: 8px;
        vertical-align: text-bottom;
      }
    }
  }

  &__body {
    width: 100%;
    height: calc(100% - @header-height);
    padding: 0 24px;
  }
}

.shipment-files-table {
  table {
    thead {
      th {
        height: 60px;
        line-height: 60px;
        border: 0;
        font-weight: 500;
        font-size: 16px;
        color: #8C8C8C;
        padding: 0 16px;
        background-color: #FFF;

        &:before {
          display: none;
        }
      }
    }

    tbody {
      td {
        height: 72px;
        line-height: 72px;
        border: 0;
        padding: 0 16px;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
