.big-number-overview {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &__num-block {
    width: calc(100%/3);
    padding: 24px 0;
    text-align: center;

    &--num {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;

      &.purple {
        color: #CD8DF7;
      }

      &.light-blue {
        color: #30BEFC;
      }

      &.blue {
        color: #5F78EB;
      }

      &.orange {
        color: #FDAB4F;
      }

      &.green {
        color: #A1D32D;
      }
    }

    &--text {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #949494;
    }
  }
}
