@import '~antd/dist/antd.css';
@import 'stylesheets/customized.less';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 16px;
}

#root {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  padding: 48px;
  box-sizing: border-box;

  .new-btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #EFEFEF;
    border-radius: 8px;
    margin-bottom: 48px;
    cursor: pointer;

    &:hover {
      background-color: #CCCCCC;
    }
  }
}

.rpg-text-limit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rpg-text-limit--one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rpg-text-limit--two-line {
  &:extend(.rpg-text-limit);
  -webkit-line-clamp: 2;
}

.rpg-text-limit--three-line {
  &:extend(.rpg-text-limit);
  -webkit-line-clamp: 3;
}
