.rpg-avatar {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 6px;
  font-size: 14px;
  color: #FFF;

  &.large {
    width: 116px;
    height: 116px;
    font-size: 48px;
  }

  &.middle {
    width: 46px;
    height: 46px;
    font-size: 18px;
  }

  &.small {
    width: 24px;
    height: 24px;
    font-size: 10px;
  }

  &.w-32 {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }

  &.w-42 {
    width: 42px;
    height: 42px;
    font-size: 18px;
  }

  &.w-128 {
    width: 128px;
    height: 128px;
    font-size: 48px;
  }

  &:last-child {
    margin-right: 0;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100%;
  }

  &__default {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFF;
  }
}
