.shipment-containers {
  &__top-bar {
    display: flex;
    width: 100%;
    height: 42px;
    margin-bottom: 28px;
    justify-content: space-between;

    &--btns {
      display: flex;
    }

    &--btn {
      display: inline-block;
      line-height: 42px;
      font-weight: 500;
      font-size: 14px;
      height: 42px;
      padding: 0 28px;
      cursor: pointer;

      &.add {
        background-color: #0170FF;
        color: #FFF;
        margin-right: 8px;
      }

      &.download {
        width: 210px;
        background-color: #DDE1E3;
        border: 1px solid #E6E6E6;
      }

      i {
        margin-right: 12px;
      }
    }
  }
}
