.schedule-overview {
  width: 100%;

  &__block {
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;

    &--header {
      height: 20px;
      color: #CCCCCC;
      margin-bottom: 12px;
    }

    &--body {
      width: 100%;
      color: #989292;

      ul {
        padding-inline-start: 20px;
      }
    }
  }
}
