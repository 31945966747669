.all-shipments {
  width: 100%;
  height: 100%;

  &__label {
    display: flex;
    font-size: 18px;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;

    &--create {
      width: 160px;
      height: 40px;
      line-height: 40px;
      background: #7197F8;
      border-radius: 2px;
      text-align: center;
      color: #FFF;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
  }

  &__list {

    &--searchbar {
      display: flex;
      width: 100%;
      height: 42px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }

    &--searchbar-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;

      label {
        height: 30px;
        line-height: 30px;
        font-weight: 400;
        font-size: 16px;
        padding: 0 15px;
        background: #F5F5F5;
        border-radius: 20px;
        white-space: nowrap;
        cursor: pointer;

        &.current {
          background: #F1F4FE;
          color: #2C6CE4;
        }
      }

      i.fa-solid.fa-search {
        color: #E6E6E6;
      }
    }

    &--searchbar-right {
      font-size: 16px;
      font-weight: 400;
      color: #757373;
    }
  }

  &__table {
    padding: 0 12px;

    table {
      thead {
        tr {
          th {
            height: 60px;
            line-height: 60px;
            padding: 0;
            font-weight: 600;
            font-size: 14px;
            background-color: #FFF;
            color: #6C757D;
            border: 0;
            text-align: center;
            padding: 0 16px;

            &:first-child {
              text-align: left;
            }

            &:before {
              display: none;
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background: #F2F5F7;
          }

          td {
            border-bottom: 0;
            padding: 12px 16px;
            color: #6C757D;
            font-size: 14px;
            text-align: center;

            &:first-child {
              text-align: left;
            }

            p {
              line-height: 18px;
              margin: 0;
              text-align: center;

              &.sm-font {
                color: #6C757D;
                font-size: 14px;
              }
            }

            a {
              color: #2C6CE4;
              font-weight: 400;
              font-size: 14px;
            }

            label {
              border-radius: 20px;
              display: inline-block;
              font-size: 12px;
              height: 30px;
              line-height: 30px;
              width: 80px;

              &.Active {
                color: #1677FF;
                background: #E6F4FF;
                border: 1px solid #91CAFF;
              }

              &.Completed {
                color: #FFF2F0;
                background: #FF4D4F;
                border: 1px solid #FFCCC7;
              }
            }
            .rpg-avatar {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

.shipment-containers-status {
  display: flex;
  justify-content: center;
  gap: 40px;

  &__block {
    display: flex;
    width: 60px;
    flex-direction: column;
    align-items: center;
    font-size: 12px;

    &.center {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        right: 40px;
        width: 80px;
        border: 1px dashed #C6C6C6;
      }

      &:after {
        content: '';
        position: absolute;
        top: 8px;
        left: 40px;
        width: 80px;
        border: 1px dashed #C6C6C6;
      }
    }

    &--icon {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      justify-content: center;
      align-items: center;

      &.onvessel {
        background-color: #FFCCC7;
      }

      &.arrived {
        background-color: #BFF058;
      }

      &.pickedup {
        background-color: #2C6CE4;
      }
    }

    &--label {
      color: #464646;
      margin-top: 6px;
    }

    &--content {
      color: #464646;
      margin-top: 6px;
      font-weight: 500;
    }
  }
}
