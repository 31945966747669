.company-info {
  width: 100%;
  height: 100%;

  &.with-margin {
    margin-left: 12px;
  }

  &__header {
    width: 100%;
    height: 32px;
    line-height: 32px;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
  }

  &__placeholder {
    margin-top: 50px;
    margin-left: 32px;

    &--text {
      line-height: 28px;
      font-size: 20px;
      color: rgba(31, 56, 88, 1);
      font-weight: 500;
      margin-bottom: 30px;
    }

    &--btns {
      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #1F3858;
        margin: 0 24px;
      }
    }

    &--btn {
      display: inline-block;
      width: 240px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;

      &.block {
        background: #0170FF;
        color: #fff;
      }

      &.border {
        color: #0170FF;
        border: 1px solid #0170FF;
      }
    }
  }

  &__basic {
    width: 100%;
    border: 1px solid #EDEBEB;
    border-radius: 6px;
    color: #646566;

    &--label {
      width: 100%;
      height: 48px;
      line-height: 48px;
      padding: 0 34px;
      color: #000;
      box-sizing: border-box;
      border-bottom: 1px solid #EDEBEB;

      span {
        display: inline-block;
        margin-left: 34px;
        font-size: 12px;

        &.pending {
          color: orange;
        }
      }
    }

    &--main {
      width: 100%;
      padding: 34px 34px 58px 34px;
      box-sizing: border-box;
    }

    &--column-block {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &.inline {
        display: flex;
        gap: 48px;
        justify-content: space-between;
      }
    }

    &--inline-column {
      width: calc(50% - 24px);
    }

    &--column-label {
      font-weight: 500;
      margin-bottom: 6px;
    }

    &--column-content {
      width: 100%;
      height: 40px;
      padding: 0 16px;
      line-height: 40px;
      font-weight: 500;
      background-color: #F7F8FA;
      box-sizing: border-box;

      &.large {
        padding: 8px 16px;
        line-height: 24px;
        height: 120px;
      }
    }
  }

  &__members {
    margin-top: 28px;

    &--label {
      line-height: 22px;
      margin-bottom: 24px;
    }

    &--content {
      width: 100%;

      table {
        width: 100%;

        td {
          padding: 0 14px;

          i {
            cursor: pointer;
          }

          &.warning {
            color: orange;
          }
        }

        thead {
          color: #797878;
          border-bottom: 2px solid #C6C6C6;

          tr {
            height: 42px;
            line-height: 42px;
          }
        }

        tbody {
          border-bottom: 1px solid #E6E2E2;

          tr {
            height: 60px;
            line-height: 60px;

            &:nth-child(odd) {
              background-color: #F9F9F9;
            }
          }
        }
      }
    }
  }
}
