@left-width: 380px;

.channel-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;

  &__placeholder {
    position: absolute;
    color: #979797;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    i {
      display: block;
      margin-bottom: 16px;
      font-size: 32px;
      font-weight: 400;
    }

    span {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__list {
    width: @left-width;
    height: 100%;
    padding: 24px 18px;
    border-right: 1px solid #E0E0E0;

    &--header {
      margin-bottom: 30px;

      i.fa-solid.fa-search {
        color: #E6E6E6;
      }
    }

    &--body {
      width: calc(100% + 36px);
      height: calc(100% - 72px);
      margin: 0 -18px;
      padding: 0 18px;
      overflow-y: auto;
    }
  }

  &__channel-body {
    width: calc(100% - @left-width);
    height: 100%;
    flex-grow: 1;

    &--header {
      display: flex;
      width: 100%;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 16px;
      border-bottom: 1px solid #E0E0E0;
    }

    &--header-info {
      width: calc(100% - 100px);
      height: 60px;
      line-height: 60px;
      color: #6C757D;
      font-weight: 500;
      font-size: 16px;

      label {
        padding: 4px 10px;
        background: #4D8ACE;
        border-radius: 20px;
        margin-left: 14px;
        color: #fff;
        font-size: 14px;
      }

      span.divider {
        display: inline-block;
        height: 24px;
        border-left: 1px solid #CEC8C8;
        margin: 0 20px;
        vertical-align: middle;
      }

      span.desc {
        font-weight: 400;
        font-size: 14px;
        color: #9D8F8F;
      }
    }

    &--header-btns {
      display: inline-block;
      width: 80px;
      text-align: right;

      i {
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        color: #8D8888;
        border-radius: 4px;

        &:last-child {
          margin: 0;
        }

        &:hover {
          color: #000;
          background-color: #F9F9F9;
        }
      }
    }

    &--body {
      width: 100%;
      height: calc(100% - 60px);
    }
  }
}
