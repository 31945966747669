.watch-list-table {
  table {
    border: 1px solid #ECECEC;
    border-bottom: 0;
  }

  .ant-table-thead {
    .ant-table-cell {
      height: 60px;
      padding: 0 16px;
      color: #797878;
      font-weight: 500;
      font-size: 14px;
      background-color: #FFF;
      border-right: 1px solid #ECECEC;
      text-align: center;
      border-bottom-width: 3px;

      &:first-child {
        border-right: 0;
      }

      &:last-child {
        border-right: 0;
      }

      &:before {
        display: none;
      }
    }
  }

  .ant-table-tbody {
    tr {
      background-color: #fff;

      td {
        color: #797878;
        border: 0;
        border-right: 1px solid #ECECEC;
        border-bottom: 1px solid #ECECEC;

        &:first-child {
          border-right: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  &__unitinfo {
    p.id {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 2px;
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__holds {
    display: flex;
    justify-content: space-around;

    &--block {
      width: 33%;
      color: #5A5858;

      p {
        text-align: center;
        margin: 0;
        font-size: 14px;
        line-height: 1.5;

        &.status {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin-top: 10px;
        }
      }
    }
  }

  &__type {
    font-size: 14px;
  }

  &__actions {
    text-align: center;
    font-size: 22px;
    color: #6236FF;

    i {
      cursor: pointer;
    }
  }
}
