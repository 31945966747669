.matters-overview {
  width: 100%;

  &__block {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(4, 4, 4, 0.08);
    border-radius: 4px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    &--header {
      display: flex;
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      justify-content: space-between;
      margin-bottom: 18px;

      b {
        font-weight: 600;
        color: #2F2F2F;
      }

      label {
        font-weight: 400;
        padding: 0 12px;
        border-radius: 4px;

        &.pending {
          color: #EC8205;
          background-color: #FFE9D1;
        }
      }
    }

    &--footer {
      display: flex;
      width: 100%;
      height: 24px;
      line-height: 24px;
      justify-content: space-between;

      span {
        color: #B1B1B1;
        font-size: 12px;
        font-weight: 400;

        i {
          margin-right: 4px;
          font-size: 12px;
        }
      }
    }
  }
}
