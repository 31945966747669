.appointment-info-modal {
  &__block {
    margin-bottom: 30px;

    &--label {
      margin-bottom: 16px;
    }

    &--input {
      width: 100%;
    }
  }
}
