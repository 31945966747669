.watch-list {
  width: 100%;
  height: 100%;
  padding: 36px;

  &__label {
    display: flex;
    height: 40px;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    &--add-btn {
      font-weight: 400;
      font-size: 14px;
      color: #FFF;
      padding: 8px 10px;
      background: #2C6CE4;
      border-radius: 4px;
      cursor: pointer;

      i {
        margin-right: 8px;
      }
    }
  }
}
