.collaborator-block {
  display: flex;
  width: 100%;
  height: 32px;
  justify-content: space-between;

  &__info {
    display: flex;
    align-items: center;

    &--content {
      margin-left: 2px;
    }

    &--name {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }

    &--email {
      line-height: 12px;
      font-weight: 400;
      font-size: 12px;
      color: #767676;
    }
  }

  &__setting {
    display: flex;
    height: 100%;
    align-items: center;
    text-align: right;
    font-weight: 400;
    font-size: 12px;
    color: #767676;
    padding: 0 8px;
    border-radius: 2px;

    &.ant-dropdown-open {
      background: #DCDEDF;
    }

    &:hover {
      background: #DCDEDF;
    }

    i {
      margin-left: 4px;
    }
  }
}
