@sidebar-width: 220px;

.dashboard-layout {
  display: flex;
  width: 100%;
  height: 100%;

  &__sidebar {
    display: flex;
    width: @sidebar-width;
    height: 100%;
    box-shadow: 4px 0 8px -6px #CCC;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
  }

  &__sidebar-top {
    width: 100%;
  }

  &__sidebar-bottom {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 18px 0;
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
  }

  &__logo {
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 28px;
    box-sizing: border-box;
    text-align: center;

    img {
      width: 180px;
    }
  }

  &__avatar-block {
    display: flex;
    width: 100%;
    height: 36px;
    box-sizing: border-box;
    justify-content: space-between;
    cursor: pointer;

    &--left {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &--right {
      display: flex;
      height: 100%;
      align-items: center;

      i {
        font-size: 24px;
      }
    }

    &--name {
      margin-left: 8px;
      font-weight: 400;
    }
  }

  &__nav-block {
    width: 100%;

    &--item {
      display: inline-block;
      width: 100%;
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.4);
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 18px;
      cursor: pointer;

      label.unread {
        display: inline-block;
        margin-left: 4px;
        width: 18px;
        height: 18px;
        line-height: 16px;
        font-size: 10px;
        text-align: center;
        color: #FFF;
        background: #EC8205;
        border: 1px solid #FFFFFF;
        border-radius: 100%;
        box-sizing: border-box;
        vertical-align: text-bottom;
      }

      i {
        width: 20px;
        text-align: center;
        margin-right: 10px;

        &.warning {
          margin-left: 8px;
          color: red;
        }
      }

      &.current {
        color: #000;
        background: #F1F1F1;
      }

      &:hover {
        color: #000;
        background: #F1F1F1;
      }

      &.with-sub-menu {
        margin-bottom: 0;
      }

      &.sub-menu {
        margin-bottom: 0;
        padding-left: 50px;
        background-color: #FFF;
      }

      &.last-sub-menu {
        margin-bottom: 18px;
      }
    }

    &:after {
      content: '';
      display: block;
      width: calc(100% - 40px);
      margin: 0 auto;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      margin-bottom: 18px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__body {
    position: relative;
    width:  calc(100% - @sidebar-width);
    height: 100%;
    overflow-y: auto;
    z-index: 0;
  }
}
