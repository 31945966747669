.cron-job-logs {
  width: 100%;
  height: 100%;
  padding: 60px;
  box-shadow: inset 0 0 10px #f8a100;

  &__tool-bar {
    display: flex;
    margin-bottom: 8px;

    &--filter {
      margin-left: 12px;
    }
  }

  &__status-bar {
    display: flex;
    margin-bottom: 8px;

    &--block {
      margin-left: 18px;

      label {
        color: #A1A1A1;
        font-weight: 500;
        margin-right: 8px;
      }

      span {
        font-weight: 500;
        color: red;

        &[data-count='0'] {
          color: #A1A1A1;
          font-weight: 200;
        }

        &:before {
          content: attr(data-count);
        }
      }
    }
  }

  &__detail_panel {
    white-space: pre-wrap;
  }

  &__log-status {
    &.SKIP {
      color: red;
    }

    &.ERROR {
      color: red;
    }

    &.MAX-TRY-FAIL {
      color: red;
    }
  }
}
