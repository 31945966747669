@left-width: 380px;

.collaborators-list {
  display: flex;
  width: 100%;
  height: 100%;

  &__left {
    width: @left-width;
    height: 100%;
    padding: 28px 12px;
    overflow-y: auto;
    border-right: 1px solid #DEDEDE;
    box-sizing: border-box;

    i.fa-solid.fa-search {
      color: #E6E6E6;
    }
  }

  &__user-list {
    margin-top: 20px;
  }

  &__user-block {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 4px 12px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;

    &.current {
      background-color: #E4F8F6;
    }

    &:hover {
      background-color: #E4F8F6;
    }

    &--avatar {
      margin-right: 12px;
    }

    &--info {
      width: calc(100% - 42px - 12px);
      height: 100%;
    }

    &--name {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }

    &--email {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #ABA9A9;
    }
  }

  &__right {
    position: relative;
    width: calc(100% - @left-width);
    height: 100%;

    &--delete-btn {
      position: absolute;
      top: 22px;
      right: 28px;
      color: #859BC4;
      font-size: 18px;
      cursor: pointer;
    }
  }

  &__user-bg {
    width: 100%;
    height: 33vh;
    background-color: #DDDAD7;
    background-repeat: no-repeat;
    background-position-x: right;
  }

  &__user-info {
    position: absolute;
    top: calc(33vh - 100px);
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-50%);

    &--name {
      margin-top: 22px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    }

    &--email {
      margin-top: 6px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ABA9A9;
    }
  }
}
