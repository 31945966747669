.signin-layout {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 50px 68px;

  &__logo {
    position: absolute;
    top: 50px;
    left: 68px;
    width: 200px;
    height: 48px;

    img {
      width: 200px;
    }
  }

  &__main {
    position: absolute;
    top: 20%;
    width: calc(100% - 134px);
    height: 320px;
    // transform: translateY(-30%);
  }
}

.signin-layout {
  &__form {
    max-width: 520px;
  }

  &__title {
    color: #1F3858;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 40px;
    text-align: center;

    &.with-subtitle {
      margin-bottom: 12px;
    }
  }

  &__subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #AAAAAB;
    margin-bottom: 28px;
    text-align: center;
    margin-left: -75px;
    margin-right: -75px;
  }

  &__link-align-right {
    display: block;
    // color: #006FFE;
    font-size: 12px;
    text-align: right;
  }

  &__submit-button {
    width: 100%;
    margin-top: 30px;
    background-color: #3FD19D !important;
    color: #fff !important;
  }

  &__text-info {
    margin-top: 28px;
    font-size: 16px;
    text-align: center;
  }
}
