.signup {
  max-width: 520px;
  margin: 0 auto;

  &__inline-block {
    display: flex;
    justity-content: space-between;
    gap: 24px;

    .ant-form-item {
      width: 50%;
    }
  }
}
