@nav-height: 60px;

.appointments-layout {
  width: 100%;
  min-height: 100%;
  background-color: #F2F2F2;

  &__nav {
    position: sticky;
    top: 0;
    display: flex;
    width: 100%;
    height: @nav-height;
    font-size: 22px;
    background-color: #FFF;
    box-shadow: 0 12px 12px 8px #F9F9F9;

    &--back-btn {
      width: @nav-height;
      height: @nav-height;
      line-height: @nav-height;
      text-align: center;
      color: #2C6CE4;
    }

    &--info {
      height: @nav-height;
      line-height: @nav-height;
      flex-shrink: 0;

      i {
        color: #BAB5B5;
        margin-right: 12px;
      }

      span {
        display: inline-block;
        font-weight: 500;
        font-size: 16px;
      }

      &:after {
        content: '';
        display: inline;
        height: 24px;
        border-right: 1px solid #BAB5B5;
        margin: 0 38px 0 24px;
        vertical-align: bottom;
      }
    }

    &--navbar {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &--left-navs {
      height: 100%;
    }

    &--left-nav-item {
      display: inline-block;
      height: 100%;
      line-height: @nav-height;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      vertical-align: top;
      margin-right: 30px;
      cursor: pointer;

      &.current {
        color: #000;
        border-bottom: 2px solid #000;
      }

      &:hover {
        color: #000;
        border-bottom: 2px solid #000;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &--right-navs {
      color: #AAA4A4;
      padding-right: 36px;
    }

    &--right-nav-item {
      display: inline-block;
      height: @nav-height;
      line-height: @nav-height;
      font-size: 24px;
      margin-right: 16px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__body {
    width: 100%;
    height: calc(100% - @nav-height);
  }
}
