.matter-chat-overview {
  width: 100%;

  &__main {
    width: 100%;
    padding: 16px 12px;
    box-sizing: border-box;
    font-weight: 400;
    line-height: 20px;
    background-color: #F4F1FF;
    border-radius: 8px 8px 0 0;

    &--name {
      font-size: 16px;
      color: #000;
      margin-bottom: 18px;

      i {
        margin-right: 8px;
        color: #D492CE;
      }
    }

    &--date {
      font-size: 14px;
      color: #7C7C7C;

    }
  }

  &__footer {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-radius: 0 0 8px 8px;
    justify-content: space-between;
    align-items: center;

    &--members {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &--info {
      font-weight: 400;
      font-size: 14px;
      color: #ABABAB;
    }
  }
}
