.all-appointments {
  width: 100%;
  height: 100%;
  padding: 36px;

  &__label {
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}
