.add-container-modal {
  // &__input {
  //   overflow-y: auto;

  //   .ant-select-selector {
  //     height: 280px;
  //     align-items: flex-start !important;
  //   }
  //   .ant-select-selection-placeholder {
  //     top: 14px !important;
  //   }
  // }
  &__body {
    display: flex;
    width: 100%;
  }

  &__section {
    position: relative;
    width: 50%;
    height: 260px;
    box-sizing: border-box;

    &:first-child {
      padding-right: 32px;
      border-right: 1px solid #DADADA;

      &:after {
        content: 'OR';
        display: block;
        position: absolute;
        top: 50%;
        right: -12px;
        color: #7D7D7D;
        font-weight: 500;
        font-size: 16px;
        padding: 14px 0;
        background-color: #FFF;
        transform: translateY(-50%);
      }
    }

    &:last-child {
      padding-left: 32px;
    }
  }

  &__form {
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .ant-form-item {
      margin-bottom: 18px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }

      label {
        height: 20px !important;
        line-height: 20px !important;
      }
    }
  }

  &__upload {
    &--header {
      display: flex;
      width: 100%;
      height: 20px;
      margin-bottom: 12px;
      justify-content: space-between;

      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      a {
        font-weight: 400;
        font-size: 12px;
        color: #6236FF;
        line-height: 20px;
      }
    }

    &--main {
      display: flex;
      width: 100%;
      height: 220px;
      border: 1px dashed #ACA9A9;
      padding: 0 46px;
      border-radius: 6px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
    }

    &--placeholder {
      width: 100%;
      height: 100%;
      color: #C5C5C5;
      font-size: 12px;
      cursor: pointer;

      p {
        text-align: center;
        margin-bottom: 16px !important;
        line-height: 18px;
      }

      i {
        font-size: 24px;
      }

      b {
        color: #000;
      }
    }

    &--uploaded-info {
      height: 100%;
      padding: 14px 20px;
      margin: 0 -46px;
      overflow-y: auto;
    }
  }
}
