.collaborator-invitation-link {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 10px;
  justify-content: space-between;

  &__link {
    width: 128px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #2C6CE4;
    border: 1px solid #A8A8A8;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    i {
      font-size: 14px;
      font-weight: 600;
      vertical-align: middle;
    }
  }

  &__save {
    width: 100px;
    height: 40px;
    line-height: 40px;
    background: #2376E5;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
      background: #CDD2DB;
    }
  }
}
