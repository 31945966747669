.containers-overview-block {
  width: 100%;

  table {
    width: 100%;

    td {
      padding: 0 12px;

      &.center {
        text-align: center;
      }
    }

    thead {
      width: 100%;
      height: 32px;
      color: #797878;
      font-weight: 600;
      font-size: 14px;
      border-bottom: 2px solid #C6C6C6;
    }

    tbody {
      color: #000;
      font-size: 14px;
      font-weight: 400;

      tr {
        width: 100%;
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #E6E2E2;
        background-color: #F9F9F9;

        &:nth-child(even) {
          background-color: #FFF;
        }
      }
    }

    label {
      padding: 2px;

      &.success {
        color: #FFF;
        background: #58F067;
      }
    }
  }
}
