.signup-layout {
  display: flex;
  width: 100%;
  height: 100%;

  &__main {
    width: 55%;
    height: 100%;
    padding: 10vh 0;
    box-sizing: border-box;

    &--logo {
      max-width: 520px;
      margin: 0 auto;
      margin-bottom: 64px;

      img {
        height: 50px;
      }
    }
  }

  &__bg {
    position: relative;
    width: 45%;
    height: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    &--cover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      z-index: -1;
    }

    &--layout {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(22, 22, 22, 0.67);
      z-index: -1;
    }

    &--main {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-content: center;
      max-width: 600px;
      margin: 0 auto;
      padding: 15% 0;
      color: #FFF;
    }

    &--text-content {
    }

    &--first-title {
      font-weight: 500;
      font-size: 32px;
    }

    &--second-title {
      font-weight: 500;
      font-size: 20px;
    }

    &--div-block {
      height: 10vh;
    }

    &--description {
      font-size: 16px;
      font-weight: 500;
    }

    &--carousel {
      position: relative;
      width: 100%;
      height: 260px;
      background-color: rgba(45, 51, 69, 0.88);

      .ant-carousel {
        width: 100%;
        height: 100%;
      }
    }

    &--quote {
      position: absolute;
      width: 100px;
      height: 100px;
      left: 0;
      top: -28px;
    }
  }
}

.cit-carousel {
  width: 100%;
  height: 100%;

  &__content {
    padding: 58px 48px 32px 48px;
    color: #FFF;

    &--main {
      font-size: 14px;
      font-weight: 500;
    }

    &--userinfo {
      display: flex;
      width: 100%;
      height: 46px;
      margin-top: 34px;
    }

    &--avatar {
      width: 46px;
      height: 46px;
      border-radius: 100%;
      margin-right: 12px;
      background-color: #D9D9D9;
    }

    &--user-name {
      font-size: 16px;
      font-weight: 500;
    }

    &--user-role {
      font-size: 14px;
      font-weight: 400;
      color: #7B7B7B;
    }
  }

  &__dots {
    bottom: -38px !important;

    li {
      width: 10px !important;

      button {
        width: 10px !important;
        height: 10px !important;
        border-radius: 100% !important;
        cursor: pointer;
      }

      &.slick-active {
        button {
          background-color: #FAAC01 !important;
          cursor: pointer;
        }
      }
    }
  }
}

.signup-layout {
  &__form {
    max-width: 520px;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 40px;
    color: #949494;
  }

  &__submit-button {
    width: 100%;
    margin-top: 30px;
    background-color: #3FD19D !important;
    color: #fff !important;
  }

  &__text-info {
    margin-top: 28px;
    font-size: 16px;
  }

  &__link {
    color: #3FD19D;
  }

  &__link-button {
    color: #3FD19D;
    font-size: 16px;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
  }

  &__20vh-div {
    height: 20vh;
  }
}
