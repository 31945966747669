.shipment-overview {
  display: flex;
  width: 100%;

  &__full-width {
    width: 100%;
  }

  &__left-block {
    width: calc(200%/3 - 12px;);
    margin-right: 24px;
  }

  &__right-block {
    width: calc(100%/3 - 12px;);
  }

  &__block {
    width: 100%;
    padding: 18px 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    &--label {
      display: flex;
      line-height: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      justify-content: space-between;
      margin-bottom: 24px;

      label {
        font-weight: 500;
        font-size: 16px;
      }

      i {
        color: #A4A4A4;
        margin-right: 8px;
        cursor: pointer;
        font-size: 24px;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--body {
      width: 100%;
    }
  }
}
