.team-info {
  width: 100%;

  &__team-block {
    border: 1px solid #EDEBEB;
    border-radius: 6px;

    &--label {
      display: flex;
      width: 100%;
      height: 48px;
      padding: 0 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #EDEBEB;
      align-items: center;
      justify-content: space-between;
    }

    &--label-btn {
      color: #2C6CE4;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }

    &--content {
      width: 100%;
      padding: 36px 20px 46px 20px;
      box-sizing: border-box;
    }
  }

  &__team-table {
    width: 100%;

    thead {
      tr {
        height: 60px;
        border-bottom: 2px solid #E5E2E2;

        th {
          color: #797878;
          font-weight: 500;
        }
      }
    }

    tbody {
      tr {
        height: 60px;
        border-bottom: 1px solid #E5E2E2;

        td {
          text-align: center;
          font-weight: 400;
        }
      }
    }

    .action-in-table {
      color: #6236FF;
      cursor: pointer;
    }
  }
}

