.channel-list {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &__block {
    display: flex;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    gap: 6px;
    margin-bottom: 8px;

    &.current {
      background: #F4F3F3;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: #F4F3F3;
    }

    &--icon {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #FFF;
      border-radius: 100%;
      background: #FDD547;
      flex-shrink: 1;
    }

    &--content {
      width: calc(100% - 46px);
    }

    &--name-block {
      display: flex;
      width: 100%;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 2px;

      span.name {
        width: calc(100% - 100px);
        font-size: 14px;
        color: #000;
      }

      span.date {
        width: 100px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.4);
        text-align: right;
      }
    }

    &--last-msg {
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.4);

        &.warnning {
          color: #EC8205;
        }
      }

      label.unread {
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 16px;
        font-size: 10px;
        text-align: center;
        color: #FFF;
        background: #EC8205;
        border: 1px solid #FFFFFF;
        border-radius: 100%;
        box-sizing: border-box;
        vertical-align: text-bottom;
        flex-shrink: 0;
      }
    }
  }
}
