.admin-containers {
  width: 100%;
  height: 100%;
  padding: 60px;
  box-shadow: inset 0 0 10px #f8a100;

  &__container-num {
    p {
      margin: 0;
    }

    &--bol {
      color: #969696;
      font-size: 12px;
    }

    &--num {
      display: inline-block;
      padding:  2px 6px;
      background-color: #F4F4F4;
      color: #707070;
    }
  }

  &__status {
    span {
      color: #FFF;
      border-radius: 6px;
      padding: 4px 6px;
      font-size: 12px;

      &.OnVessel {
        color: #FAAD14;
        background: #FFFBE6;
        border: 1px solid #FFCCC7;
      }

      &.Arrived {
        color: #1677FF;
        background: #E6F4FF;
        border: 1px solid #91CAFF;
      }

      &.Discharged {
        color: #1677FF;
        background: #E6F4FF;
        border: 1px solid #91CAFF;
      }

      &.NotFound {
        color: #FF4D4F;
        background: #FFF2F0;
        border: 1px solid #FFCCC7;
      }

      &.GateOut {
        color: #52C41A;
        background: #F6FFED;
        border: 1px solid #B7EB8F;
      }

      &.EmptyReturned {
        color: #000;
        background: #F5F5F5;
        border: 1px solid #D9D9D9;
      }

      &.Loading {
        color: #1677FF;
        font-size: 14px;

        &:after {
          content: '...';
          display: inline;
        }
      }

      &.Other {
        color: #000;
        background: #F5F5F5;
        border: 1px solid #D9D9D9;
      }
    }
  }

  &__creator {
    color: #167FFF;
    cursor: pointer;

    p {
      margin: 0;
    }

    &--name {
      // color: #969696;
      font-size: 12px;
    }

    &--email {
      display: inline-block;
      padding:  2px 6px;
      background-color: #F4F4F4;
      // color: #707070;
    }
  }
}
