.containers-cache {
  width: 100%;
  height: 100%;
  padding: 60px;
  box-shadow: inset 0 0 10px #f8a100;

  &__btns {
    display: flex;
    gap: 12px;

    button.warning {
      background-color: #CB4538;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    button.success {
      background-color: #3A71DA;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
