.shipment-detail-block {
  width: 100%;
  height: 100%;

  &__top {
    display: flex;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 22px;
    justify-content: space-between;
    align-items: center;

    &--name {
      display: flex;
      align-items: center;

      i {
        display: inline-block;
        width: 46px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        color: #FFF;
        background-color: #75B8FB;
        font-size: 20px;
        border-radius: 6px;
        margin-right: 8px;
      }
    }

    &--name-info {
      display: inline-block;

      p {
        margin: 0;

        &.name {
          color: #6236FF;
          line-height: 20px;
          font-size: 16px;
          margin-bottom: 4px;
          font-weight: 500;
        }

        &.date {
          color: #9C9C9C;
          line-height: 18px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    &--datetime {
      display: flex;
      align-items: center;

      font-size: 16px;
      color: #7C7C7C;

      i {
        display: inline-block;
        width: 38px;
        height: 38px;
        line-height: 36px;
        text-align: center;
        margin-right: 8px;
        border-radius: 100%;
        border: 1px dashed #CDC2C2;
      }
    }

    &--datetime-info {
      display: inline-block;

      p {
        font-size: 14px;
        margin: 0;
        line-height: 18px;

        &.date {
          color: #000;
        }
      }
    }
  }

  &__desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #444343;
    padding-bottom: 36px;
    border-bottom: 1px solid #ECE6E6;
    margin-bottom: 16px;
  }

  &__members {
    width: 100%;

    &--label {
      font-weight: 500;
      font-size: 16px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    &--avatars-list {
      display: flex;
      width: 100%;
    }

    &--add-member {
      width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #838080;
      background-color: #FAF7F7;
      border-radius: 100%;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.shipment-select-eta {
  width: 38px;
  height: 38px;
  text-align: center;
  padding: 0 !important;
  margin-right: 8px !important;
  border: 1px dashed #CDC2C2 !important;
  border-radius: 100% !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:after {
    font-family: "Font Awesome 6 Free";
    content: "\f133";
    color: #7C7C7C;
    font-size: 16px;
  }

  .ant-picker-input {
    display: none;
  }
}
